/* eslint-disable no-use-before-define, prefer-arrow-callback */
const mobileNavToggle = document.querySelector('.js-main-header__toggle');
const navPanel = document.querySelector('.js-mobile-nav');
const subMenuButtons = [...document.querySelectorAll('.js-mobile-nav__submenu-button')];
const backButtons = [...document.querySelectorAll('.js-mobile-nav__back-button')];

/*
* Open the mobile menu and listen for keyboard events
*/
function openMobileMenu() {
  // navPanel.removeEventListener('transitionend', navTransition);
  mobileNavToggle.setAttribute('aria-expanded', 'true');
  navPanel.style.visibility = 'visible';
  navPanel.classList.add('is-active');
  navPanel.addEventListener('keydown', (event) => {
    if (event.keyCode === 27) {
      closeMobileMenu();
    }
  });
}

/*
* Close the mobile menu and remove keyboard event listener
*/
function closeMobileMenu() {
  navPanel.classList.remove('is-active');
  mobileNavToggle.setAttribute('aria-expanded', 'false');
  mobileNavToggle.focus();
  navPanel.addEventListener('transitionend', function hideEl() {
    navPanel.style.visibility = 'hidden';
    navPanel.removeEventListener('transitionend', hideEl, true);
  }, true);
}

/*
* Open the sub menu and listen for keyboard events
*/
function openSubMenu(event) {
  const subMenu = event.target.nextElementSibling;
  const backButton = subMenu.querySelector('.js-mobile-nav__back-button');
  subMenu.style.visibility = 'visible';
  subMenu.classList.add('is-active');
  backButton.focus();
  subMenu.addEventListener('keydown', (e) => {
    if (e.keyCode === 27) {
      closeSubMenu(e);
      e.stopImmediatePropagation(); /* Only close the current subMenu */
    }
  });
}

/*
* Close the sub menu and remove keyboard event listener
*/
function closeSubMenu(event) {
  const parentMenu = event.target.parentElement;
  parentMenu.classList.remove('is-active');
  parentMenu.previousElementSibling.focus();
  parentMenu.addEventListener('transitionend', function hideEl() {
    parentMenu.style.visibility = 'hidden';
    parentMenu.removeEventListener('transitionend', hideEl, true);
  }, true);
}

/*
* Mobile nav menu
*/
export default function init() {
  if (navPanel) {
    mobileNavToggle.addEventListener('click', (event) => {
      // Toggle aria-expanded on button click
      const expanded = event.currentTarget.getAttribute('aria-expanded');
      if (expanded === 'false') {
        openMobileMenu();
      } else {
        closeMobileMenu();
      }
    });
    subMenuButtons.forEach((subMenuButton) => {
      subMenuButton.addEventListener('click', (event) => {
        openSubMenu(event);
      });
    });
    backButtons.forEach((backButton) => {
      backButton.addEventListener('click', (event) => {
        closeSubMenu(event);
      });
    });
  }
}
