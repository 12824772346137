const $searchBox = document.querySelector('.ais-SearchBox');
const $searchBoxMock = $searchBox.querySelector('.c-search__input--mock');
const algoliaAppId = $searchBox.dataset.app;
const algoliaSearchApiKey = $searchBox.dataset.key;

export default function init() {
  $searchBoxMock.addEventListener('click', () => {
    Promise.all([
      import('vue'),
      import('vue-instantsearch'),
      import('algoliasearch/lite'),
    ]).then(([{ default: Vue, nextTick }, { default: InstantSearch }, { default: algoliasearch }]) => {
      $searchBoxMock.style.display = 'none';

      Vue.use(InstantSearch);

      // eslint-disable-next-line no-new
      new Vue({
        el: '#auto-suggest',
        components: {},
        delimiters: ['${', '}'],
        data() {
          return {
            searchClient: algoliasearch(algoliaAppId, algoliaSearchApiKey),
            query: '',
          };
        },
        methods: {
          onSelect(selected) {
            if (selected) {
              this.query = selected.item.name;
            }
          },
          indicesToSuggestions(indices) {
            return indices.map(({ hits }) => ({ data: hits }));
          },
          requestSearchResults(query) {
            window.location.href = `/search/results?q=${encodeURI(query)}`;
          },
          focusInput() {
            // Ugly, but works 🤔
            setTimeout(() => this.$el.querySelector('input').focus(), 150);
          },
        },
      });
    });
  });
}
