export default function init() {
  const stripes = document.querySelectorAll('.c-image__stripe--appear');

  const active = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('c-image__stripe--appear--inview');
      } else {
        entry.target.classList.remove('c-image__stripe--appear--inview');
      }
    });
  };

  const intersectionObserver = new IntersectionObserver(active);
  for (let i = 0; i < stripes.length; i += 1) {
    intersectionObserver.observe(stripes[i]);
  }
}
