import '@styles/components/c-dropdown-nav.scss';

export default function init() {
  const dropdownLinks = document.querySelectorAll('.js-dropdown');
  // Main nav link (parent) events
  dropdownLinks.forEach((liEl) => {
    const dropdownEl = liEl.querySelector('.c-dropdown-nav');
    liEl.addEventListener('mouseenter', () => {
      dropdownEl.classList.add('is-active');
    });
    liEl.addEventListener('mouseleave', () => {
      dropdownEl.classList.remove('is-active');
    });
  });
}
