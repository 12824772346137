export default function init() {

  // Uses mobileNav.js for mobile navigation toggle

  // Selecting elements
  const navLinks = document.querySelectorAll('.js-navLink');
  const navImageColumns = document.querySelectorAll('.js-nav-image-column');
  const mainNavLink = document.querySelectorAll('.js-main-nav-link');
  const mobileNavChevronOpen = document.querySelectorAll('.js-mobile-nav-chevron-open');
  const mobileNavChevronClose = document.querySelectorAll('.js-mobile-nav-chevron-close');
  const mobileNavSubMenu = document.querySelectorAll('.js-mobile-nav-sub-menu');
  const mobileExternalLinksMenu = document.querySelectorAll('.js-data-external-links-menu');


  if (navLinks) {

    // Desktop Navigation

    // Reset image column on main nav link hover
    mainNavLink.forEach((navLink) => {
      navLink.addEventListener('mouseover', (event) => {
        navImageColumns.forEach((navImageColumn) => {
          let customAttributeValue = navImageColumn.getAttribute('data-column-heading');

          if (customAttributeValue === 'Stadia & Leisure' || customAttributeValue === 'Accreditations' || customAttributeValue === 'Design' || customAttributeValue === 'Vacancies' || customAttributeValue === 'Products' || customAttributeValue === 'Projects') {
            navImageColumn.style.display = 'block';
          } else {
            navImageColumn.style.display = 'none';
          }
        });
      });
    });

    // Show image column on hover
    navLinks.forEach((navLink) => {
      navLink.addEventListener('mouseover', (event) => {
        navImageColumns.forEach((navImageColumn) => {
          let customAttributeValue = navImageColumn.getAttribute('data-column-heading');

          if (customAttributeValue === event.target.innerText) {
            navImageColumn.style.display = 'block';
          } else {
            navImageColumn.style.display = 'none';
          }
        });
      });
    });

    mainNavLink.forEach((navLink) => {
      const megaMenu = navLink.nextElementSibling;

      navLink.addEventListener('mouseover', (event) => {
        // Remove background classes from all nav links and mega menus
        mainNavLink.forEach((link) => {
          link.classList.remove('c-desktop-nav__link--bg-clay', 'c-desktop-nav__link--bg-steel-grey', 'c-desktop-nav__link--bg-electric-blue', 'c-desktop-nav__link--bg-blue', 'is-active');
          if (link.nextElementSibling) {
            link.nextElementSibling.classList.remove('c-desktop-nav-mega-menu--bg-clay', 'c-desktop-nav-mega-menu--bg-steel-grey', 'c-desktop-nav-mega-menu--bg-electric-blue', 'c-desktop-nav-mega-menu--bg-blue');
          }
        });

        if (megaMenu) {
          switch (navLink.innerText) {
            case 'Products':
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-clay');
              navLink.classList.add('c-desktop-nav__link--bg-clay', 'is-active');
              break;
            case 'Services':
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-steel-grey');
              navLink.classList.add('c-desktop-nav__link--bg-steel-grey', 'is-active');
              break;
            case 'Projects':
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-electric-blue');
              navLink.classList.add('c-desktop-nav__link--bg-electric-blue', 'is-active');
              break;
            case 'About':
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-blue');
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              break;
            case 'Contact':
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-steel-grey');
              navLink.classList.add('c-desktop-nav__link--bg-steel-grey', 'is-active');
              break;
            case 'Careers':
              navLink.classList.add('c-desktop-nav__link--bg-clay', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-clay');
              break;
            case 'News':
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-blue');
              break;
            default:
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-blue');
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              break;
          }
        } else {
          console.log('No mega menu found for this menu item')

          switch (navLink.innerText) {
            case 'Products':
              navLink.classList.add('c-desktop-nav__link--bg-clay', 'is-active');
              break;
            case 'Services':
              navLink.classList.add('c-desktop-nav__link--bg-steel-grey', 'is-active');
              break;
            case 'Projects':
              navLink.classList.add('c-desktop-nav__link--bg-electric-blue', 'is-active');
              break;
            case 'About':
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              break;
            case 'Contact':
              navLink.classList.add('c-desktop-nav__link--bg-steel-grey', 'is-active');
              break;
            case 'Careers':
              navLink.classList.add('c-desktop-nav__link--bg-clay', 'is-active');
              break;
            case 'News':
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              break;
            default:
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              break;
          }
        }
      });

      navLink.addEventListener('mouseout', (event) => {
        if (!megaMenu || !megaMenu.contains(event.relatedTarget)) {
          navLink.classList.remove('c-desktop-nav__link--bg-clay', 'c-desktop-nav__link--bg-steel-grey', 'c-desktop-nav__link--bg-electric-blue', 'c-desktop-nav__link--bg-blue', 'is-active');
          if (megaMenu) {
            megaMenu.classList.remove('c-desktop-nav-mega-menu--bg-clay', 'c-desktop-nav-mega-menu--bg-steel-grey', 'c-desktop-nav-mega-menu--bg-electric-blue', 'c-desktop-nav-mega-menu--bg-blue');
          }
        }
      });

      if (megaMenu) {
        megaMenu.addEventListener('mouseover', (event) => {
          switch (navLink.innerText) {
            case 'Products':
              navLink.classList.add('c-desktop-nav__link--bg-clay', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-clay');
              break;
            case 'Services':
              navLink.classList.add('c-desktop-nav__link--bg-steel-grey', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-steel-grey');
              break;
            case 'Projects':
              navLink.classList.add('c-desktop-nav__link--bg-electric-blue', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-electric-blue');
              break;
            case 'About':
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-blue');
              break;
            case 'Contact':
              navLink.classList.add('c-desktop-nav__link--bg-steel-grey', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-steel-grey');
              break;
            case 'Careers':
              navLink.classList.add('c-desktop-nav__link--bg-clay', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-clay');
              break;
            case 'News':
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-blue');
              break;
            default:
              navLink.classList.add('c-desktop-nav__link--bg-blue', 'is-active');
              megaMenu.classList.add('c-desktop-nav-mega-menu--bg-blue');
              break;
          }
        });

        megaMenu.addEventListener('mouseout', (event) => {
          if (!navLink.contains(event.relatedTarget)) {
            navLink.classList.remove('c-desktop-nav__link--bg-clay', 'c-desktop-nav__link--bg-steel-grey', 'c-desktop-nav__link--bg-electric-blue', 'c-desktop-nav__link--bg-blue', 'is-active');
            megaMenu.classList.remove('c-desktop-nav-mega-menu--bg-clay', 'c-desktop-nav-mega-menu--bg-steel-grey', 'c-desktop-nav-mega-menu--bg-electric-blue', 'c-desktop-nav-mega-menu--bg-blue');
          }
        });

        // Highlight active nav link
        mainNavLink.forEach((navLink) => {
          const currentUrl = window.location.href;
          const baseUrl = `${window.location.protocol}//${window.location.host}`;

          if (navLink.href === currentUrl) {
            switch (navLink.href) {
              case `${baseUrl}/products`:
                navLink.classList.add('c-desktop-nav__link--active-clay');
                break;
              case `${baseUrl}/products-and-services`:
                navLink.classList.add('c-desktop-nav__link--active-steel-grey');
                break;
              case `${baseUrl}/projects`:
                navLink.classList.add('c-desktop-nav__link--active-electric-blue');
                break;
              case `${baseUrl}/about`:
                navLink.classList.add('c-desktop-nav__link--active-blue');
                break;
              case `${baseUrl}/contact`:
                navLink.classList.add('c-desktop-nav__link--active-steel-grey');
                break;
              case `${baseUrl}/careers`:
                navLink.classList.add('c-desktop-nav__link--active-clay');
                break;
              case `${baseUrl}/news`:
                navLink.classList.add('c-desktop-nav__link--active-blue');
                break;
              default:
                navLink.classList.add('c-desktop-nav__link--active-blue');
                break;
            }
          }
        });


      }
    });

    // Mobile Navigation

    // Show menu on click
    mobileNavChevronOpen.forEach((navChevronOpen) => {
      navChevronOpen.addEventListener('click', (event) => {
        let menuTitle = navChevronOpen.previousSibling.innerHTML;
        mobileNavSubMenu.forEach((navSubMenu) => {
          let customAttributeValue = navSubMenu.getAttribute('data-sub-menu');

          if (customAttributeValue === menuTitle) {
            navSubMenu.classList.add('mobile-sub-menu-open');
          }
        });

        mobileExternalLinksMenu.forEach((navExternalLinks) => {
          let customAttributeValue = navExternalLinks.getAttribute('data-external-links-menu');

          if (customAttributeValue === menuTitle) {
            navExternalLinks.classList.add('mobile-sub-menu-open');
          }
        });
      });
    });

    // Close menu on click
    mobileNavChevronClose.forEach((navChevronClose) => {
      navChevronClose.addEventListener('click', (event) => {
        mobileNavSubMenu.forEach((navSubMenu) => {
          navSubMenu.classList.remove('mobile-sub-menu-open');
        });

        mobileExternalLinksMenu.forEach((navExternalLinks) => {
          navExternalLinks.classList.remove('mobile-sub-menu-open');
        });
      });
    });
  } else {
    console.log('No navLinks found');
  }
}
